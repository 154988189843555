import axios from 'axios';
import Estilos from '../../context/EstilosGlobales'
import {useEffect,useState} from 'react'
import { Typography } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

export default function CarouselExperiencias(){

    const [Estado,setEstado] = useState("inicio");
    const [Experiencias,setExperiencias] = useState([]);
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarExperiencias();
        }
    })
    
    const cargarExperiencias=()=>{
    
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/experiencias_cargar.php"
        }).then((resp)=>{
            var res = resp.data;
            setExperiencias(res);
        })
    }   

    return(
        <div className="rounded rounded shadow-sm">
            <div className="w-100">
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        {
                            Experiencias.map((experiencia,index)=>{
                                var activo="";
                                var colorFondo = "";
                                if(index===0) activo="active";
                                
                                if(index===0) colorFondo=Estilos.colorPrimario;
                                else if(index===1) colorFondo=Estilos.colorQuinto;
                                else if(index===2) colorFondo=Estilos.colorCuarto;
                                else if(index===3) colorFondo=Estilos.colorSecundario;
                                else if(index===4) colorFondo=Estilos.colorTercero;
                                else colorFondo=Estilos.colorPrimario;
                                return(
                                    <div key={index} className={"carousel-item " + activo} style={{ backgroundColor: colorFondo }}>
                                        <div className="w-100 h-100 d-flex flex-md-row flex-column justify-content-center align-items-center px-md-5 py-md-5">
                                            <div className="bg-white p-2 rounded-circle">
                                                <img className="rounded-circle" style={{maxWidth:"160px"}} src={process.env.PUBLIC_URL+"/assets/imagenes/experiencias/"+experiencia.imagen} alt="..."></img>
                                            </div>
                                            <div className="bg-white rounded shadow-sm d-flex flex-column px-md-4 px-2 py-md-4 py-2 ml-md-2 p-2 p-md-0">
                                                <div className="d-flex flex-row align-items-end">
                                                    <Typography className="font-weight-bold" variant="h6">{experiencia.nombre}</Typography>
                                                    {
                                                        experiencia.subtitulo?
                                                        <Typography className="font-weight-bold ml-1" variant="caption" style={{color:Estilos.colorPrimario,marginBottom:"5px"}}>({experiencia.subtitulo})</Typography>
                                                        :""
                                                    }
                                                </div>
                                                <div className="w-100 border-top position-relative">
                                                    <div className="position-absolute" style={{backgroundColor:Estilos.colorPrimario,zIndex:"100",top:"-2px",left:"0px",width:"50px",height:"4px"}}></div>
                                                </div>
                                                <div className="container-fluid mt-3">
                                                    <div className="position-relative">
                                                        <FormatQuoteIcon className="position-absolute" style={{top:"5px",left:"-13px",color:Estilos.colorSecundario}}></FormatQuoteIcon>
                                                        <Typography className="px-3 py-2">
                                                            {experiencia.comentario}
                                                        </Typography>
                                                        <FormatQuoteIcon className="position-absolute" style={{top:"5px",right:"0px",color:Estilos.colorSecundario}}></FormatQuoteIcon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }                            
                    </div>
                </div>
            </div>
        </div>
    );
}