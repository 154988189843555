import { Typography } from "@material-ui/core";
import Estilos from '../context/EstilosGlobales'
import {useSpring,animated} from 'react-spring'
import { useEffect, useState } from "react";

export default function BannerTitulo(props){

    const [Estado,setEstado] = useState("inicio");
    const [imagen,setimagen] = useState("");

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarBanner();
        }
    })

    const cargarBanner=()=>{
        var Rand = Math.floor((Math.random() * 3) + 1);
        setimagen("bannertitulo"+Rand+".jpg");
        setEstado("cargado");
    }
    

    
    /* var imagen = "bannertitulo"+Rand+".jpg"; */
    const animprops = useSpring({opacity: 1, from: {opacity: 0}})

    return(
        <div className="container-fluid" style={{backgroundColor:"#1a1919"}}>
            <div className="row">
            <animated.div className="w-100" style={animprops}>
                <div className="w-100 overflow-hidden position-relative" style={{height:"180px",backgroundColor:"#1a1919"}}>
                    <img className="w-100" src={process.env.PUBLIC_URL+"/assets/imagenes/bannerstitulo/"+imagen} alt="..." />
                    <div className="w-100 h-100 position-absolute" style={{zIndex:"100",top:"0px",left:"0px",opacity:"0.6",backgroundColor:"#1a1919"}}>
                        
                    </div>
                    <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center position-absolute" style={{zIndex:"103",top:"0px",left:"0px"}}>
                        <div className="position-relative">
                            <Typography variant="h3" className="text-white font-weight-bold">
                                {props.children}
                            </Typography>
                            <div className="d-flex">
                                <div className="border-bottom bg-dark position-relative" style={{ width: "100%", height: "1px" }}>
                                </div>
                                <div className="position-absolute" style={{ width: "40px", height: "10px", left: "0px", backgroundColor: Estilos.colorSecundario, bottom: "-5px" }}></div>
                                <div className="position-absolute" style={{ width: "40px", height: "10px", left: "40px", backgroundColor: Estilos.colorTercero, bottom: "-5px" }}></div>
                                <div className="position-absolute" style={{ width: "40px", height: "10px", left: "80px", backgroundColor: Estilos.colorQuinto, bottom: "-5px" }}></div>
                                <div className="position-absolute" style={{ width: "40px", height: "10px", left: "120px", backgroundColor: Estilos.colorCuarto, bottom: "-5px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </animated.div>
            </div>
        </div>

    );
}