import Header from '../components/Header'
import ContenidoPatologia from '../components/patologia/ContenidoPatologia'
import Footer from '../components/Footer'

export default function Inicio(){
    return(
        <div>
            <Header></Header>
            <ContenidoPatologia></ContenidoPatologia>
            <Footer></Footer>
        </div>
    );
}

