import Ruta from '../Ruta'
import BannerTitulo from '../BannerTitulo'
import { Card, Typography } from '@material-ui/core'
import Titulo from '../Titulo'
import axios from 'axios';
import {useState,useEffect} from 'react'
import EstilosGlobales from '../../context/EstilosGlobales';
import { Skeleton } from '@material-ui/lab';
import { animated, useSpring } from 'react-spring';
import { useParams } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useQuill } from 'react-quilljs';
import { Helmet } from 'react-helmet-async';



export default function ContenidoProfesional(){

    let {nombre} = useParams();

    const [Estado,setEstado] = useState("inicio");
    const [Profesional,setProfesional] = useState({
        datos_profesional:{
            nombre:""
        }
    });
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarProfesional();
        }
    })
    
    const cargarProfesional=()=>{
        setEstado("cargando");

        var datos = new FormData();
        datos.append("nombre",nombre)

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/profesional_cargar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            if(res[0]==="realizado"){
                setEstado("cargado");
                setProfesional(res);
                
            }
        })
    }

    const animprops = useSpring({
        opacity:1,
        transform:"translate(0px,0px)",
        from:{
            opacity:0,
            transform:"translate(0px,-30px)"
        }
    })

    return(
        <div>
            <Helmet>
                <title>
                    {Profesional.datos_profesional.nombre+" - Vitral Salud"}
                </title>
            </Helmet>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Nuestro Equipo",ruta:"/nuestroequipo"},
                    {nombre:Profesional.datos_profesional.nombre},
                ]}></Ruta>
                
                <BannerTitulo>Nuestro Equipo</BannerTitulo>                
            </div>
            <div className="bg-light py-4" style={{minHeight:window.innerHeight*0.5}}>
                {
                    Estado==="cargado"?
                    <div className="container">
                        <Titulo className="my-4">{Profesional.datos_profesional.nombre}</Titulo>
                        <animated.div style={animprops} className="row">
                            <div className="col-md-4">
                                <Card>
                                    <div>
                                        <div className="d-flex flex-row justify-content-center py-3">
                                            <div className="rounded-circle shadow-sm p-1" style={{ maxWidth: "180px", backgroundColor: EstilosGlobales.colorPrimario }}>
                                                <img src={process.env.PUBLIC_URL + "/assets/imagenes/equipo/" + Profesional.datos_profesional.img} alt="..." className="img-fluid rounded-circle p-1 bg-white" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-4">
                                        <Typography className="text-center font-weight-bold" variant="h6">
                                            {Profesional.datos_profesional.nombre}
                                        </Typography>
                                        <Typography className="text-center font-weight-bold" style={{ color: EstilosGlobales.colorPrimario }}>
                                            {Profesional.datos_profesional.cargo}
                                        </Typography>
                                    </div>
                                    <div className="border-top mb-3">
                                        
                                    </div>
                                    <div className="pb-4 px-1 px-md-4">
                                        <span className="badge px-3 py-1" style={{backgroundColor:EstilosGlobales.colorTercero}}>
                                            <Typography className="font-weight-bold text-white" variant="body2">Titulos :</Typography>
                                        </span>
                                        {
                                            Estado==="cargado" && Profesional.titulos?
                                            <div className="mt-2">
                                                {
                                                    Profesional.titulos.map((titulo,index)=>{
                                                        return(
                                                            <div key={index} className="d-flex flex-row align-items-start">
                                                                <ArrowRightIcon style={{color:EstilosGlobales.colorTercero}}></ArrowRightIcon>
                                                                <Typography variant="body2">
                                                                    {titulo}
                                                                </Typography>
                                                            </div>
                                                        )
                                                    })
                                                } 
                                            </div>:""
                                        }
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-8">
                                {
                                    Estado==="cargado" && Profesional.contenido?
                                    <Card className="p-1 p-md-4">
                                        <ProfesionalDescripcion contenido={Profesional.contenido}></ProfesionalDescripcion>
                                    </Card>
                                    :
                                    ""
                                }
                            </div>

                        </animated.div>
                    </div>
                    :
                    <div className="container">
                        <Titulo className="my-4">-</Titulo>
                        <div className="row">
                            <div className="col-md-4">
                                <Skeleton height={417} variant="rect"></Skeleton>
                            </div>
                            <div className="col-md-8">
                                <Skeleton className="position-relative" height={417} variant="rect">
                                    
                                </Skeleton>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

function ProfesionalDescripcion(props){

    let {contenido} = props

    const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    };
    

    const { quill, quillRef } = useQuill({ theme, modules});       

    useEffect(() => {
        var Estado = "inicio";

        const cargarContenido=()=>{
            Estado="cargado";
            quill.setContents(contenido)
        }

        if(Estado==="inicio"){
            if(quill){
                cargarContenido();
                quill.disable();
            }
        }   
        
        
        
    }, [quill,contenido]);


    return(
        <div className="text-dark border-0">
            <div className="border-0 p-0" ref={quillRef} />
        </div>
    );
}
