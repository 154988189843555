import axios from 'axios';
import Carousel from '../Carousel'
import {useState,useEffect} from 'react'
import { Skeleton } from '@material-ui/lab';
import LinearProgressMine from '../LinearProgressMine';
import TituloContrast from '../TituloContrast';
import Titulo from '../Titulo';
import GrupoEtareo from './GrupoEtareo'
import Estilos from '../../context/EstilosGlobales'
import CarouselExperiencias from './CarouselExperiencias'
import {animated,useSpring} from 'react-spring'
import Swal from '../../context/SwalAlert.jsx'


export default function ContenidoInicio(){

    const [Estado,setEstado] = useState("inicio");
    const [Banners,setBanners] = useState([]);
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarBanners();
        }
    })
    
    const cargarBanners=()=>{        
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/banners_cargar.php"
        }).then((resp)=>{
            var res = resp.data;
            setBanners(res);
            setEstado("cargado")
        })
    }

    const animpropsbanner = useSpring({        
        opacity:1,
        from:{
            opacity:0
        }
    })

    return(
        <div>
            <AvisoGlobal></AvisoGlobal>
            <div style={{backgroundColor:"#1a1919"}}>
                
                    <div className="container-fluid container-md">
                        {
                            Estado==="cargado"?
                            <animated.div style={animpropsbanner}> 
                                <Carousel banners={Banners}></Carousel>
                            </animated.div>
                            :
                            <div className="d-flex flex-column">                        
                                <LinearProgressMine></LinearProgressMine>
                                <Skeleton variant="rect" height={400} className="w-100"></Skeleton>
                            </div>
                        }                
                    </div>
                
            </div>
            <div style={{backgroundColor:Estilos.colorTercero}}>            
                <div className="container py-2">
                    <div className="row mt-4 mx-2">
                        <TituloContrast className="w-100" titulo="Áreas de Intervención" color={Estilos.colorPrimario}></TituloContrast>                    
                    </div>
                    <GrupoEtareo></GrupoEtareo>
                </div>
            </div>
            <div className="bg-light">
                <div className="container py-3">
                    
                    <Titulo className="my-3" titulo="Experiencias de nuestros pacientes"></Titulo>
                    
                    <div className="rounded shadow-sm">
                        <CarouselExperiencias>
                            
                        </CarouselExperiencias>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

function AvisoGlobal(){
    
    const [Estado,setEstado]=useState("inicio");

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarAviso();
        }
    })

    const cargarAviso=()=>{
        setEstado("cargando");
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/aviso_global_cargar.php"
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res);
            if(res[0]==="realizado"){
                if(parseInt(res['aviso_on'])>0){
                    Swal.fire({
                        html:res['contenido'],
                        icon:"warning",
                        confirmButtonText:"Aceptar",
                        confirmButtonColor:Estilos.colorPrimario
                    })
                    setEstado("cargado");
                }
            }
        })
    }

    return(
        <></>
    );
}
