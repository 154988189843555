import 'fontsource-roboto';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
/* import axios from 'axios' */
import { BrowserRouter, Route, Switch /*, Redirect */ } from 'react-router-dom'
import "./App.css"
import {HelmetProvider} from 'react-helmet-async'

/* Paginas */
import Inicio from './pages/Inicio'
import QuienesSomos from './pages/QuienesSomos'
import Historia from './pages/Historia'
import NuestroEquipo from './pages/NuestroEquipo'
import Profesional from './pages/Profesional'
import AreaIntervencion from './pages/AreaIntervencion'
import Patologia from './pages/Patologia'
import Contacto from './pages/Contacto'
import Telemedicina from './pages/Telemedicina'
import Pagina404 from './pages/Pagina404'
import EnConstruccion from './pages/EnConstruccion'
import Capacitaciones from './pages/Capacitaciones'

import QuillGenerator from './QuillGenerator';


/* Variables Globales Context */
/* import axios from 'axios'; */


export default function App() { 
        
    return (
        
        <HelmetProvider>
            
            <BrowserRouter basename="/">
                <Switch>
                    <Route exact path="/" component={Inicio} />
                    <Route exact path="/qsomos" component={QuienesSomos} />
                    <Route exact path="/historia" component={Historia} />
                    <Route exact path="/nuestroequipo" component={NuestroEquipo} />
                    <Route exact path="/contacto" component={Contacto} />
                    <Route exact path="/telemedicina" component={Telemedicina} />
                    <Route exact path="/nuestroequipo/profesional/:nombre" component={Profesional} />
                    <Route exact path="/areaintervencion/:getareo" component={AreaIntervencion} />
                    <Route exact path="/areaintervencion/:getareo/:patologia" component={Patologia} />
                    <Route exact path="/quill" component={QuillGenerator} />
                    <Route exact path="/blog" component={EnConstruccion} />
                    <Route exact path="/capacitaciones" component={Capacitaciones} />
                    <Route exact path="/*" component={Pagina404} />
                    
                </Switch>
            </BrowserRouter>
        </HelmetProvider>

    );
}
