import Header from '../components/Header'
import Footer from '../components/Footer'
import Contenido404 from '../components/Contenido404'
import { Helmet } from 'react-helmet-async'

export default function Pagina404(){
    return(
        <div>
            <Helmet>
                <title>
                    Pagina no encontrada - Vital
                </title>
            </Helmet>
            <Header></Header>
            <Contenido404></Contenido404>
            <Footer></Footer>
        </div>
    );
}

