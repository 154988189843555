import Header from '../components/Header'
import Footer from '../components/Footer'
import ContenidoEnConstruccion from '../components/ContenidoEnConstruccion'
import { Helmet } from 'react-helmet-async'

export default function Pagina404(){
    return(
        <div>
            <Helmet>
                <title>
                    En Construcción - Vitral Salud
                </title>
            </Helmet>
            <Header></Header>
            <ContenidoEnConstruccion></ContenidoEnConstruccion>
            <Footer></Footer>
        </div>
    );
}

