import Ruta from '../Ruta'
import BannerTitulo from '../BannerTitulo'
import Titulo from '../Titulo'
import { Card, CircularProgress } from '@material-ui/core'
import axios from 'axios';
import {useQuill} from 'react-quilljs'
import {useState,useEffect} from 'react'
import EstilosGlobales from '../../context/EstilosGlobales';



export default function ContenidoTelemedicina(){

    const [Estado,setEstado] = useState("inicio");
    const [ContenidoTele,setContenidoTele] = useState();
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarContenidoTele();
        }
    })
    
    const cargarContenidoTele=()=>{
    
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/contenido/contenido.php",
            method:"get",
            params:{
                contenido:"capacitaciones.json"
            }
        }).then((resp)=>{
            var res = resp.data;
            console.log(res);
            if(res && res.statusCode && res.statusCode===200){
                setContenidoTele(res.data);
                setEstado("cargado");
            }
        })
    }

    return(
        <div>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Telemedicina"},
                ]}></Ruta>
                
                <BannerTitulo>Capacitaciones</BannerTitulo>                
            </div>
            <div className="bg-light py-4">
                <div className="container">
                    <Titulo>Capacitaciones</Titulo>
                    <div className="row mt-3">
                
                        <div className="col-md-12">
                            <Card style={{minHeight:window.innerHeight*1}}>
                                {
                                    Estado==="cargado"?
                                    <QuillContenido contenido={ContenidoTele}></QuillContenido>:
                                    <div className="w-100 d-flex flex-row justify-content-center" style={{marginTop:"60px"}}>
                                        <CircularProgress style={{color:EstilosGlobales.colorPrimario}}></CircularProgress>
                                    </div>
                                }
                            </Card>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
            
    );
}

function QuillContenido(props){
    let {contenido} = props

    const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    };
    

    const { quill, quillRef } = useQuill({ theme, modules});       

    useEffect(() => {
        var Estado = "inicio";

        const cargarContenido=()=>{
            Estado="cargado";
            quill.setContents(contenido)
        }

        if(Estado==="inicio"){
            if(quill){
                cargarContenido();
                quill.disable();
            }
        }   
        
        
        
    }, [quill,contenido]);


    return(
        <div className="text-dark border-0">
            <div className="border-0 p-0" ref={quillRef} />
        </div>
    );
}
