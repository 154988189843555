import { Typography } from '@material-ui/core'
import BannerTitulo from '../BannerTitulo'
import Ruta from '../Ruta'
import Titulo from '../Titulo'
import {animated,useSpring} from 'react-spring'
import EstilosGlobales from '../../context/EstilosGlobales'

export default function ContenidoQuienesSomos(){


    const animprops = useSpring({opacity:1,marginTop:"32px",from:{opacity:0}})
    const animpropstext = useSpring({
        opacity:1,
        transform:"translate(0px,0px)",        
        from:{
            opacity:0,
            transform:"translate(0px,-30px)"            
        }    
    })

 
    
    return(
        <div>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Quiénes Somos"},
                ]}></Ruta>
                
                <BannerTitulo>Quiénes Somos</BannerTitulo>                
            </div>
            <div className="bg-light py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Titulo titulo="Vitral Salud"></Titulo>
                            <animated.div style={animpropstext} className="bg-white rounded border shadow-sm mt-4 text-justify p-4">
                                <Typography className="mb-3">
                                    <b>MISIÓN</b> <b style={{color:EstilosGlobales.colorPrimario}}>Vitral</b>, tiene como misión  entregar a la persona y a su familia atención en salud física y mental de manera integral, de calidad y oportuna, respetando su dignidad y basada en principios cristianos, por equipos de gran calidad humana y excelencia profesional. 
                                </Typography>
                                <Typography>
                                    <b>VISIÓN:</b> Ser un Centro reconocido por la excelencia, profesionalismo y ética en sus prácticas, así como por la calidez y cercanía de sus profesionales. Nuestro centro tiene como  uno de sus ejes, acoger a la familia desde la Empatía. Por esta razón cada terapia está diseñada considerando al ser humano en su contexto de comunidad, siendo flexibles en cuanto a  la  modalidad, duración y etapa del ciclo familiar en que se enfrenten al diagnóstico.
                                </Typography>
                            </animated.div>
                        </div>
                        <animated.div className="col-md-6" style={animprops}>
                            <img src={process.env.PUBLIC_URL+"/assets/imagenes/secciones/qsomos.jpg"} alt="" className="img-fluid shadow-sm"/>
                        </animated.div>
                    </div>
                    <div className="py-2 my-3">
                        <Titulo>¿Por qué en Curauma?</Titulo>
                        <animated.div style={animpropstext} className="row bg-white shadow-sm rounded border mt-3 p-md-4">
                            <div className="col-md-7">
                                <Typography className="text-justify">
                                El sector Curauma –Placilla, es un centro urbano con un desarrollo poblacional importante en los últimos años, al estar ubicado a 15-20 km de Viña del Mar y Valparaíso, su principal crecimiento ha sido a expensas de viviendas de uso habitacional. Familias jóvenes en su mayoría han privilegiado asentarse en la zona debido a gran oferta inmobiliaria, seguridad, acceso a colegios y comercios emergentes. La mayoría de los padres trabajan en Viña, Valparaíso, otras comunas de la región, incluso Santiago.</Typography>

                                <Typography className="mt-3 text-justify">Existen aproximadamente  6 colegios  privados, 6 particulares subvencionados y municipales aproximadamente un número similar, además de al menos 8 jardines infantiles.</Typography>
                                
                                <Typography className="mt-3 text-justify">Casi la totalidad de los alumnos viven  el sector.</Typography>
                                <Typography className="mt-3 text-justify">De estos colegios, tienen programa de integración los que cuentan con aporte estatal, aunque son heterogéneos en su funcionamiento en la práctica. </Typography>

                                <Typography className="mt-3 text-justify">Aproximadamente el  20 % de los niños en etapa escolar presentan problemas de salud mental, ya sea  problemas de aprendizaje, en la esfera del ánimo y del desarrollo,  en los que necesitan de apoyo escolar-familiar-personal  y  otros que  requieren terapias de apoyo  permanentes y transitorias.</Typography>
                                <Typography className="mt-3 text-justify">La frecuencia y duración de estos apoyos hacen que muchos niños no accedan a ellos, debido a  la distancia de los centros existentes y debido al tiempo escaso de los padres.</Typography>
                            </div>
                            <div className="col-md-5">
                                <img src={process.env.PUBLIC_URL+"/assets/imagenes/secciones/curauma.jpg"} alt="" className="img-fluid shadow-sm border"/>
                            </div>

                        </animated.div>
                    </div>
                    <div className="py-2 my-3">
                        <Titulo>Salud Mental en Chile</Titulo>
                        <animated.div style={animpropstext} className="row bg-white shadow-sm rounded border mt-3 p-md-4">
                            <div className="col">
                                <Typography className="text-justify">
                                Aproximadamente cuatro de cada cinco personas que necesitan atención por trastornos mentales, neurológicos y por uso de sustancias en los países con ingresos bajos y medios-bajos no la reciben. Las intervenciones, aunque estén disponibles, a menudo no se basan en pruebas o en la evidencia, ni son de alta calidad. La OMS lanzó recientemente el Programa de Acción para Superar las Brechas en Salud Mental, dirigido a los países con ingresos bajos y medios-bajos con el objetivo de mejorar la atención de los trastornos mentales, neurológicos y por uso de sustancias.
                                <br></br><br></br>  
                                Existe una idea ampliamente compartida, pero errónea, con respecto a que todas las intervenciones de salud mental son sofisticadas y que solamente pueden ser ofrecidas por personal altamente especializado. Investigaciones en años recientes han demostrado la factibilidad de ofrecer intervenciones farmacológicas y psicológicas en el nivel de atención primaria. 
                                <br></br><br></br>  
                                Estas intervenciones, al ser más accesibles, hacen que las personas puedan acceder a ellas de forma oportuna, incluso preventiva. Desde el sistema de salud público hay avances en la materia, con capacitación en psiquiatría a médicos generales y de familia. Pero desde el sistema privado no existe esta alternativa, siendo expuestos a tener que pagar grandes cifras de dinero para ser atendidos por profesionales en el área, considerando que las terapias son a largo plazo y que muchas Isapres en Chile, tienen un tope en cuanto al número de prestaciones.
                                <br></br><br></br>  
                                Se estima  que  80 % de las patologías en salud mental son de baja o mediana complejidad. Que, incluso estos pacientes se sienten mucho más cercanos a  consultar a su médico de familia, ya que enfrentarse a la especialidad de psiquiatría aun genera estigmatización.
                                <br></br><br></br>  
                                Es así que nace Vitral, un centro de Salud integral con énfasis en la Salud mental, que brinda  diagnóstico, tratamiento y rehabilitación a  usuarios y sus familias, desde  una mirada multidisciplinaria, buscando siempre la excelencia a bajo costo. (ya que la salud no debe ser vista como un negocio)
                                <br></br><br></br>                                  
                                </Typography>
                                <a target="_blank" rel="noreferrer" href="https://www.who.int/topics/mental_health/es/"><Typography>https://www.who.int/topics/mental_health/es/</Typography></a>
                            </div>                         
                        </animated.div>
                    </div>
                </div>

            </div>

        </div>
    );
}