import { Card, Typography } from "@material-ui/core";
import Ruta from './Ruta'
import BannerTitulo from './BannerTitulo'
import Estilos from '../context/EstilosGlobales'
import { Link } from "react-router-dom";
import BuildIcon from '@material-ui/icons/Build';
export default function ContenidoEnConstruccion(){
    return(
        <div>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"En Construccion"}                    
                ]}></Ruta>
                <BannerTitulo>Sección en Construcción</BannerTitulo>                               
                <div className="bg-light">
                    <div className="container py-4">
                        <div className="row">
                            <div className="col">                            
                                <Card className="py-4 px-1 px-md-4" style={{minHeight:"300px"}}>
                                    <div className="d-flex flex-row">
                                        <Typography style={{color:Estilos.colorPrimario}} className="font-weight-bold" variant="h4">En Construcción</Typography>
                                        <BuildIcon className="ml-3" fontSize="large"></BuildIcon>

                                    </div>
                                    <Typography variant="body1" className="mt-4">
                                        Esta sección se encuentra en construcción y próxima a ser implementada. Puedes seguir navegando en <Link to="/">Vitral Salud</Link>
                                    </Typography>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}