import { Button, ClickAwayListener, Typography } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { useEffect,useState } from "react";
import axios from 'axios'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Link } from "react-router-dom";
import Estilos from '../context/EstilosGlobales'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReorderIcon from '@material-ui/icons/Reorder';
import EstilosGlobales from "../context/EstilosGlobales";
import { animated, useSpring } from "react-spring";
import EmailIcon from '@material-ui/icons/Email';
import { Phone } from "@material-ui/icons";

export default function Header(){

    const [Estado,setEstado]= useState("inicio");
    const [DatosHeader,setDatosHeader] = useState({
        telefono:"",
        email:"",
        whatsapp:"#",
        facebook:"#",
        instagram:"#"
    })

    const Menu = [
        {
            nombre:"Inicio",ruta:"/",desplegable:false,
            submenu:[]            
        },
        {
            nombre:"Nosotros",ruta:"/qsomos",desplegable:true,
            submenu:[
                {nombre:"Quienes Somos",ruta:"/qsomos"},
                {nombre:"Historia",ruta:"/historia"}
            ]            
        },
        {
            nombre:"Nuestro Equipo",ruta:"/nuestroequipo",desplegable:false,
            submenu:[]            
        },
        {
            nombre:"Intervenciones",ruta:"/",desplegable:true,
            submenu:[
                {nombre:"Adolescentes",ruta:"/areaintervencion/Adolescentes"},
                {nombre:"Adulto Mayor",ruta:"/areaintervencion/Adulto Mayor"},
                {nombre:"Adultos",ruta:"/areaintervencion/Adultos"},
                {nombre:"Familias",ruta:"/areaintervencion/Familias"},
                {nombre:"Niños",ruta:"/areaintervencion/Niños"},
                {nombre:"Parejas",ruta:"/areaintervencion/Parejas"}
            ]            
        },
        {
            nombre:"Contacto",ruta:"/contacto",desplegable:false,
            submenu:[]            
        },
    ]

    const MenuSecundario = [
        {nombre:"Telemedicina",ruta:"/telemedicina"},                
        {nombre:"Blog",ruta:"/blog"},        
        {nombre:"Capacitaciones",ruta:"/capacitaciones"}
    ]

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarDatosHeader();
        }
    })

    const cargarDatosHeader=()=>{
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/global/config.json"
        }).then((resp)=>{
            var res = resp.data;
            setDatosHeader(res.Empresa);
            setEstado("cargado");
        })
    }

    const [MenuConfig,setMenuConfig] = useState({
        webpay:false
    });

    useEffect(()=>{
        const cargarMenuConfig=()=>{
            //console.log("Cargando configuracion");
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/menuconfig.php",
            }).then((resp)=>{
                var res = resp.data;
                setMenuConfig(res);
            })
        }
        //console.log("useEffect final");
        cargarMenuConfig();
    },[])

    return(
        <div>
            <div className="container-fluid bg-light border">
                <div className="row py-1 px-2 justify-content-between align-items-center">
                    <div className="col-sm-6 text-left d-flex flex-row align-items-center">
                        <Typography className="text-dark" variant="caption">
                            <Phone className="mr-1" style={{fontSize:"14px"}}></Phone>
                            <a className="text-decoration-none text-dark" href={"tel:"+DatosHeader.telefono}>{DatosHeader.telefono}</a>
                        </Typography>
                        <Barra className="mx-1"></Barra>
                        {/* <Typography className="text-dark" variant="caption">
                            <b className="mr-2">Telefono 2:</b>
                            <a className="text-decoration-none text-dark" href={"tel:"+DatosHeader.telefono2}>{DatosHeader.telefono2}</a>
                        </Typography>
                        <Barra className="mx-1"></Barra> */}
                        <div className="d-flex flex-row text-dark align-items-center" variant="caption">
                            <EmailIcon className="mr-1" style={{fontSize:"14px"}}></EmailIcon>
                            <a className="text-decoration-none text-dark" href={"mailto:"+DatosHeader.email}>
                                <Typography variant="caption">
                                {DatosHeader.email}
                                </Typography>
                            </a>
                        </div>                        
                    </div>

                    <div className="col-sm-6 text-right align-items-center">
                        <a target="_blank" rel="noreferrer" className="text-decoration-none bg-success text-white px-1 pt-0 pb-1 rounded font-weight-bold" href={"https://wa.me/"+DatosHeader.whatsapp}>
                            <WhatsAppIcon fontSize="small"></WhatsAppIcon>   
                            <Typography variant="caption" className="ml-1 font-weight-bold">Whatsapp</Typography>
                        </a>
                        <Barra className="mx-1"></Barra>   
                        <a target="_blank" rel="noreferrer" className="text-decoration-none" href={DatosHeader.facebook}>
                            <FacebookIcon fontSize="small" style={{color:"#3578E5"}}></FacebookIcon>   
                        </a>
                        <Barra className="mx-1"></Barra>   
                        <a target="_blank" rel="noreferrer" className="text-decoration-none" href={DatosHeader.instagram}>
                            <InstagramIcon fontSize="small" style={{color:"#ED4956"}}></InstagramIcon>                   
                        </a>
                    </div>
                    
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-between pb-2 pt-3">
                    <div className="col-6 col-md-4">
                        <div style={{maxWidth:"250px"}}>
                            <Link to="/" className="text-decoration-none">
                                <img className="img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/logofn.png"} alt="log"></img>
                            </Link>
                        </div>
                    </div>
                    <MenuPrincipal Menu={Menu} className="col-md-8 d-none d-md-block"></MenuPrincipal>  
                    <MenuPrincipalMovil Menu={Menu} className="text-right col-6 d-block d-md-none"></MenuPrincipalMovil>               
                </div>
            </div>
            <div style={{backgroundColor:Estilos.colorPrimario}}>
                <div className="container mt-4">
                    <div className="row justify-content-center py-2 align-items-center">
                        {
                            MenuSecundario.map((menu,index)=>{
                                return(
                                    <Link className="text-decoration-none mx-2" key={index} to={menu.ruta}>
                                        <Typography className="text-white font-weight-bold">
                                            {menu.nombre}
                                        </Typography>
                                    </Link>
                                )
                            })                            
                        }
                        {
                            MenuConfig.webpay && MenuConfig.webpay===true?
                            <a href="https://www.webpay.cl/portalpagodirecto/pages/institucion.jsf?idEstablecimiento=67404385" className="text-decoration-none ml-2" target="_blank" rel="noreferrer">
                                <div className="contImg bg-white rounded shadow-sm border" style={{maxWidth:"120px"}}>
                                    <img className="img-fluid" src={ process.env.PUBLIC_URL+"/assets/imagenes/wp_logo_min.png" } alt="..."/> 
                                </div>    
                            </a>:""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

function Barra(props){
    return(        
        <span className={props.className+" text-secondary"}>|</span>
    );
}

function MenuPrincipal(props){

    let {Menu} = props;
    return(
        <div className={props.className}>
            <div className="d-flex flex-row align-items-end h-100">                
                {
                    Menu.map((menu,index)=>{
                        if(menu.desplegable){
                            return(
                                <MenuDesplegable className="px-2" key={index} menu={menu}></MenuDesplegable>
                            )
                        }else{
                            return(
                                <Link className="px-2 text-decoration-none" key={index} to={menu.ruta}>
                                    <Typography variant="body1" className="text-dark">
                                        {menu.nombre}
                                    </Typography>
                                </Link>
                            )
                        }
                    })
                }                    
            </div>
        </div>
    );
}

function MenuPrincipalMovil(props){

    const [Desplegado,setDesplegado] = useState(false);

    const animprops = useSpring({
        width:"100%",top:"0px",right:"0px",zIndex:"2000",
        opacity:Desplegado?1:0,
        transform:Desplegado?"translate(0px,0px)":"translate(100%,0px)",
        display:"block",
        from:{
            opacity:0,
            transform:"translate(100%,0px)",
            display:"none"
        }
    })

    const handleDesplegar=()=>{
        setDesplegado(!Desplegado);
    }

    return(
        <div className={props.className}>
            
            <Button onClick={handleDesplegar} className="text-white" style={{backgroundColor:EstilosGlobales.colorPrimario}}><ReorderIcon fontSize="default"></ReorderIcon></Button>
            
                
                <animated.div className="position-fixed bg-white py-5" style={animprops}>
                    <div className="row mx-3 justify-content-end">
                        <Button onClick={handleDesplegar} className="text-center text-white" style={{backgroundColor:EstilosGlobales.colorPrimario}} variant="contained">X</Button>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        {
                            props.Menu.map((menu,index)=>{
                                if(!menu.desplegable){
                                    return(
                                        <Link key={index} to={menu.ruta}>
                                            <Typography className="font-weight-bold" variant="h6" style={{color:EstilosGlobales.colorPrimario}}>{menu.nombre}</Typography>
                                        </Link>
                                    )
                                }else{
                                    return(
                                        <MenuDesplegableMovil key={index} menu={menu}></MenuDesplegableMovil>
                                    )
                                }
                            })
                        }
                    </div>
                </animated.div>
            
        </div>
    );
}

function MenuDesplegableMovil(props){

    const [Desplegado,setDesplegado] =useState(false)


    
    let {menu}=props;
    return(
        <div className="text-center">
            <div className="d-flex flex-row align-items-center">
                <Typography onClick={()=>{setDesplegado(!Desplegado)}} className="font-weight-bold" variant="h6" style={{color:EstilosGlobales.colorPrimario}}>{menu.nombre}</Typography>
                
                <ArrowDropDownIcon style={{color:Estilos.colorPrimario}} fontSize="small"></ArrowDropDownIcon>
            </div>
                    
            {
            Desplegado?
            <div className="d-flex flex-column">
                {
                    props.menu.submenu.map((submenu,index)=>{
                        return(
                            <Link key={index} to={submenu.ruta}>
                                <Typography variant="body2">{submenu.nombre}</Typography>
                            </Link>
                        )
                    })
                }

            </div>:""
            }
            
        </div>
    );
}

function MenuDesplegable(props){

    const [Desplegado,setDesplegado]=useState(false);



    let {menu} = props
    return(
        <div className={props.className+" position-relative"}>
            <ClickAwayListener onClickAway={()=>{setDesplegado(false);}}>
                <div className="d-flex flex-row align-items-center" style={{cursor:"pointer"}}
                    onClick={()=>{
                        setDesplegado(!Desplegado);
                    }}
                >
                    <Typography>{menu.nombre}</Typography>            
                    <ArrowDropDownIcon style={{color:Estilos.colorPrimario}} fontSize="small"></ArrowDropDownIcon>
                </div>
            </ClickAwayListener>
            {
                Desplegado?
                <div className="position-absolute bg-white d-flex flex-column border rounded shadow-sm p-3" style={{top:"25px",minWidth:"100px",zIndex:"700"}}>
                    {
                        menu.submenu.map((submenu,index)=>{
                            return(
                                <Link className="text-decoration-none my-1" to={submenu.ruta} key={index}>
                                    <Typography variant="body2" style={{color:Estilos.colorPrimario}}>
                                        {submenu.nombre}
                                    </Typography>
                                </Link>             
                            )       
                        })
                    }                
                </div>:""
            }
        </div>
    );
}

