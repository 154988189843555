import { Typography } from '@material-ui/core'
import BannerTitulo from '../BannerTitulo'
import Ruta from '../Ruta'
import Titulo from '../Titulo'
import {animated,useSpring} from 'react-spring'
import EstilosGlobales from '../../context/EstilosGlobales'
import { Link } from 'react-router-dom'

export default function ContenidoQuienesSomos(){


    const animprops = useSpring({opacity:1,marginTop:"32px",from:{opacity:0}})
    const animpropstext = useSpring({
        opacity:1,
        transform:"translate(0px,0px)",        
        from:{
            opacity:0,
            transform:"translate(0px,-30px)"            
        }    
    })

 
    
    return(
        <div>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Historia"},
                ]}></Ruta>
                
                <BannerTitulo>Nuestra Historia</BannerTitulo>                
            </div>
            <div className="bg-light py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <Titulo titulo="Vitral"></Titulo>
                            <animated.div style={animpropstext} className="bg-white rounded border shadow-sm mt-4 text-justify p-4">
                                <Typography className="mb-3">
                                    <b style={{color:EstilosGlobales.colorPrimario}}>Vitral</b> nace hace muchos años, cuando como familia nos enfrentamos a una difícil situación, nuestra segunda hija era diferente. No nos miraba, parecíamos no importarle, no jugaba ni hablaba como los otros niños, sus gustos y habilidades eran distintos. Parecía que la vida ya no sería del color que creíamos.
                                </Typography>
                                <Typography className="mb-3">
                                    Un vitral es un vidrio trabajado, con paciencia y perseverancia, donde quizás hay que empezar muchas veces, que nos exige crear, pero que también nos da la oportunidad de construir una imagen, un paisaje, una figura o un momento que nos inspire.
                                </Typography>
                                <Typography className="mb-3">
                                    Cada vitral es único, una obra de arte que no admite atajos, ni trabajos en serie mecanizados, es algo detallado, con la vida del artesano plasmada en cada trocito de cristal. 
                                </Typography>
                                <Typography className="mb-3">
                                    Nuestra vida es un vitral en que  día a día se  va construyendo en nosotros un dibujo que no comprendemos, colores que por sí solos quizás no nos gustan, va dejando todo  de ser blanco y negro, la paleta  se va tornando colorida, ya que cada prueba  es un color que trae consigo otro que lo contrasta o complementa.
                                </Typography>
                                <Typography className="mb-3">
                                    Cada color es también un recuerdo de que cada prueba no vino sola, ya que el amor y la gracia de Dios  van juntos, uno al lado del otro. 
                                </Typography>
                                <Typography className="mb-3">
                                    El vitral le muestra a otros nuestra historia, una  historia de perseverancia, madurez, amor y gracia. 
                                </Typography>
                                <Typography className="mb-3">
                                    Cada vez que contemplemos un vitral recordemos que la vida está llena de múltiples colores y multiformes caminos de amor.
                                </Typography>
                                    <Link className="text-decoration-none font-weight-bold" style={{color:EstilosGlobales.colorPrimario}} to="/nuestroequipo/profesional/Dra%20Andrea%20Arias%20Lagos">
                                        (Dra . Andrea Arias Lagos, fundadora, Centro Salud Vitral)
                                    </Link>
                            </animated.div>
                        </div>
                        <animated.div className="col-md-4" style={animprops}>
                            
                                <img src={process.env.PUBLIC_URL+"/assets/imagenes/secciones/historia.jpg"} alt="" className="img-fluid rounded shadow-sm"/>
                            
                        </animated.div>
                    </div>                    
                </div>

            </div>

        </div>
    );
}