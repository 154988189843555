import Ruta from '../Ruta'
import BannerTitulo from '../BannerTitulo'
import {useEffect,useState} from 'react'
import Titulo from '../Titulo';
import { useParams } from 'react-router';
import axios from 'axios';
import EstilosGlobales from '../../context/EstilosGlobales'
import { Card, CardActionArea, Typography } from '@material-ui/core'
import {useQuill} from 'react-quilljs'
import { Helmet } from 'react-helmet-async';

export default function ContenidoPatologia(){

    let {getareo,patologia} = useParams();

    const [Estado,setEstado] = useState("inicio");
    const [Patologia,setPatologia] = useState({
        getareo:"",
        nombre:""
    });

    const [ContenidoPatologia,setContenidoPatologia] = useState({});
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarPatologia();
        }
    })
    
    const cargarPatologia=()=>{
        setEstado("cargando");
        var datos = new FormData();
        datos.append("getareo",getareo);
        datos.append("patologia",patologia);
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/patologia_cargar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            
            if(res[0]==="realizado"){
                /* console.log(res); */
                setPatologia(res['datos_patologia']);
                setContenidoPatologia(res['contenido']);
                setEstado("cargado");
            }
        })
    }

    var random = Math.floor(Math.random() * 4) + 1;
    var color = EstilosGlobales.colorPrimario;
    if (random === 1) color = EstilosGlobales.colorPrimario;
    else if (random === 2) color = EstilosGlobales.colorTercero;
    else if (random === 3) color = EstilosGlobales.colorCuarto;
    else if (random === 4) color = EstilosGlobales.colorQuinto;

    return(
        <div>
            <Helmet>
                <title>
                    {Patologia.nombre+" - Patología - Vitral"}
                </title>
            </Helmet>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Area Intervención"},
                    {nombre:Patologia.getareo,ruta:"/areaintervencion/"+Patologia.getareo},
                    {nombre:Patologia.nombre},
                ]}></Ruta>
                
                <BannerTitulo>Patología</BannerTitulo> 
                
                {Estado==="cargado"?
                
                <div className="bg-light py-3">
                    <div className="container">
                        <Titulo  className="mt-3">{Patologia.nombre}</Titulo>
                        <div className="row py-3">
                            <div className="col-md-4">
                                <Card>
                                    <CardActionArea>
                                        <div className="w-100 overflow-hidden" style={{ height: "200px" }}>
                                            <img src={process.env.PUBLIC_URL + "/assets/imagenes/patologias/" + Patologia.imagen} alt="..." className="img-fluid w-100" />
                                        </div>
                                    </CardActionArea>
                                    <div style={{ backgroundColor: color }}>
                                        <Typography className="p-1 p-md-3 font-weight-bold text-center text-white">
                                            {Patologia.nombre}
                                        </Typography>
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-8">
                                <Card>
                                    <ContenidoPatologiaQuill contenido={ContenidoPatologia}></ContenidoPatologiaQuill>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                
                :"" }   

            </div>
        </div>

    );
}

function ContenidoPatologiaQuill(props){
    let {contenido} = props

    const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    };
    

    const { quill, quillRef } = useQuill({ theme, modules});       

    useEffect(() => {
        var Estado = "inicio";

        const cargarContenido=()=>{
            Estado="cargado";
            quill.setContents(contenido)
        }

        if(Estado==="inicio"){
            if(quill){
                cargarContenido();
                quill.disable();
            }
        }   
        
        
        
    }, [quill,contenido]);


    return(
        <div className="text-dark border-0">
            <div className="border-0 p-0" ref={quillRef} />
        </div>
    );
}