import { Card, CardActionArea, Typography } from "@material-ui/core";
import { useState,useEffect } from "react";
import axios from 'axios'
import {Link} from 'react-router-dom'
import { Skeleton } from "@material-ui/lab";
import {animated,useSpring} from 'react-spring'


export default function GrupoEtareo(){

    const [Estado,setEstado] = useState("inicio");
    const [GruposEtareos,setGruposEtareos] = useState([]);
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarGruposEtareos();
        }
    })
    
    const cargarGruposEtareos=()=>{
    
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/grupos_etareos_cargar.php"
        }).then((resp)=>{
            var res = resp.data;
            setGruposEtareos(res);
            setEstado("cargado");
        })
    }

    const animprops = useSpring({opacity: 1, from: {opacity: 0}})

    const ClaseBloques = "col-md-4 col-sm-6 my-2";

    return(
        /* Esta dentro de un container */
        <div className="py-3">
            {
                Estado==="cargado"?
                <div className="row">
                    {
                        GruposEtareos.map((grupoEtareo,index)=>{
                            return(
                                <div className={ClaseBloques} key={index}>
                                    <Link style={{textDecoration:"none"}} to={"/areaintervencion/"+grupoEtareo.nombre} className="px-0">
                                        <animated.div style={animprops}>
                                            <Card>
                                                <CardActionArea>
                                                    <img alt="l" className="img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/grupos_etareos/"+grupoEtareo.imagen}></img>
                                                    <div className="px-2 py-2">
                                                        <Typography className="font-weight-bold text-center" style={{textDecoration:"none"}} variant="h5">
                                                            {grupoEtareo.nombre}
                                                        </Typography>
                                                    </div>
                                                </CardActionArea>
                                            </Card>
                                        </animated.div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div> 
                :
                <div className="row">
                    <div className={ClaseBloques}><EsqueletoCarga></EsqueletoCarga></div>
                    <div className={ClaseBloques}><EsqueletoCarga></EsqueletoCarga></div>
                    <div className={ClaseBloques}><EsqueletoCarga></EsqueletoCarga></div>
                    <div className={ClaseBloques}><EsqueletoCarga></EsqueletoCarga></div>
                    <div className={ClaseBloques}><EsqueletoCarga></EsqueletoCarga></div>
                    <div className={ClaseBloques}><EsqueletoCarga></EsqueletoCarga></div>
                </div>
            }               
        </div>
    );
}

function EsqueletoCarga(){
    return(
        <Skeleton className="rounded shadow-sm" variant="rect" height={258}></Skeleton>
    );
}
