import Ruta from '../Ruta'
import BannerTitulo from '../BannerTitulo'
import { Button, Card, CircularProgress, TextField, Typography } from '@material-ui/core'
import Titulo from '../Titulo'
import {useState,useEffect} from 'react'
import EstilosGlobales from '../../context/EstilosGlobales'
import axios from 'axios'
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';

export default function ContenidoContacto(){

    const [DatosForm,setDatosForm] = useState({
        nombre:"",
        email:"",
        telefono:"",
        mensaje:"",
        asunto:""
    })

    const [PrintConsole,setPrintConsole] = useState("");

    const [ErrorForm,setErrorForm] = useState({
        nombre:{error:false,errortext:""},
        email:{error:false,errortext:""},
        telefono:{error:false,errortext:""},
        mensaje:{error:false,errortext:""},
        asunto:{error:false,errortext:""}
    })

    const [EstadoSubmit,setEstadoSubmit] = useState("inicio");

    const handleSubmit=()=>{
        /* console.log(DatosForm); */
        if(EstadoSubmit==="cargando" || EstadoSubmit==="submited") return false;
        var campos_requeridos = 0;
        const campo = "Campo requerido";

        var ErrorFormTemp = {
            nombre:{error:false,errortext:""},
            email:{error:false,errortext:""},
            telefono:{error:false,errortext:""},
            mensaje:{error:false,errortext:""},
            asunto:{error:false,errortext:""}
        };

        if(DatosForm.nombre.length<=0){
            campos_requeridos+=1;
            ErrorFormTemp={...ErrorFormTemp,nombre:{error:true,errortext:campo}};
        }
        if(DatosForm.telefono.length<=0){
            campos_requeridos+=1;
            ErrorFormTemp={...ErrorFormTemp,telefono:{error:true,errortext:campo}};
        }
        if(DatosForm.email.length<=0){
            campos_requeridos+=1;
            ErrorFormTemp={...ErrorFormTemp,email:{error:true,errortext:campo}};
        }
        if(DatosForm.mensaje.length<=0){
            campos_requeridos+=1;
            ErrorFormTemp={...ErrorFormTemp,mensaje:{error:true,errortext:campo}};
        }
        if(DatosForm.asunto.length<=0){
            campos_requeridos+=1;
            ErrorFormTemp={...ErrorFormTemp,asunto:{error:true,errortext:campo}};
        }

        setErrorForm(ErrorFormTemp);

        if(campos_requeridos>0){
            return false;
        }else{
            setEstadoSubmit("cargando");
            setPrintConsole(<PrintCargando></PrintCargando>)
        }

        var datos = new FormData();
        datos.append("nombre",DatosForm.nombre);
        datos.append("telefono",DatosForm.telefono);
        datos.append("email",DatosForm.email);
        datos.append("mensaje",DatosForm.mensaje);
        datos.append("asunto",DatosForm.asunto);

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/formulario_enviar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res);
            if(res[0]==="realizado"){
                setEstadoSubmit("submited");
            }
        })
    }

    const [Estado,setEstado] = useState("inicio");
    const [DatosContacto,setDatosContacto] = useState({
        facebook:"#",
        whatsapp:"#",
        instagram:"#",
    });

    useEffect(()=>{
        if(Estado==="inicio") cargarDatosContacto();
    })

    const cargarDatosContacto=()=>{
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/global/config.json"
        }).then((resp)=>{
            var res = resp.data;
            setDatosContacto({
                facebook:res.Empresa.facebook,
                whatsapp:res.Empresa.whatsapp,
                instagram:res.Empresa.instagram
            })
        })
    }


    return(
        <div>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Contacto"}                    
                ]}></Ruta>
                
                <BannerTitulo>Contacto</BannerTitulo>                
            </div>
            <div className="bg-white py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Card className="p-1 p-md-4">
                                <Titulo>Formulario</Titulo>
                                {EstadoSubmit!=="submited"?
                                <div>
                                    <div className="d-flex flex-column">
                                        <TextField 
                                        onChange={(e)=>{setDatosForm({...DatosForm,nombre:e.target.value})}}
                                        error={ErrorForm.nombre.error}
                                        helperText={ErrorForm.nombre.errortext}
                                        className="mt-3 w-75" label="Nombre" variant="standard" style={{padding:"0px"}}
                                        />
                                        <TextField 
                                        onChange={(e)=>{setDatosForm({...DatosForm,telefono:e.target.value})}}
                                        error={ErrorForm.telefono.error}
                                        helperText={ErrorForm.telefono.errortext}
                                        className="mt-2 w-75" label="Telefono" variant="standard" style={{padding:"0px"}}
                                        />
                                        <TextField 
                                        onChange={(e)=>{setDatosForm({...DatosForm,email:e.target.value})}}
                                        error={ErrorForm.email.error}
                                        helperText={ErrorForm.email.errortext}
                                        className="mt-2 w-75" label="Email" variant="standard" style={{padding:"0px"}}
                                        />
                                        <TextField 
                                        onChange={(e)=>{setDatosForm({...DatosForm,asunto:e.target.value})}}
                                        error={ErrorForm.asunto.error}
                                        helperText={ErrorForm.asunto.errortext}
                                        className="mt-2 w-75" label="Asunto" variant="standard" style={{padding:"0px"}}
                                        />

                                        <textarea 
                                        onChange={(e)=>{setDatosForm({...DatosForm,mensaje:e.target.value})}}
                                        placeholder="Escribe tu mensaje..." cols="30" rows="5" className="form-control mt-2"></textarea>
                                        {   
                                            ErrorForm.mensaje.error?
                                            <span className="text-danger font-weight-bold">* {ErrorForm.mensaje.errortext} *</span>:""
                                        }
                                        <div className="row justify-content-center">

                                            <Button style={{maxWidth:"150px",backgroundColor:EstilosGlobales.colorPrimario}} className="mt-3 mr-3 text-white font-weight-bold" variant="contained" onClick={handleSubmit}>Enviar</Button>
                                            {PrintConsole}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="d-flex flex-row justify-content-center" style={{minHeight:"500px"}}>
                                    <div style={{marginTop:"100px"}} className="text-center">
                                        <Typography style={{color:EstilosGlobales.colorPrimario}} variant="h5"  className="font-weight-bold text-center">Formulario Enviado</Typography>
                                        <Typography className="text-center">
                                            El formulario fue enviado con exito.<br></br>¡ Nos contactaremos contigo a la brevedad !
                                        </Typography>
                                    </div>

                                </div>}
                            </Card>
                        </div>
                        <div className="col-md-6">
                            <Card className="p-md-3 p-1">

                                <Titulo>Nuestras Redes</Titulo>
                                <div className="my-3">
                                    <div className="d-flex flex-row mx-3 justify-content-center">
                                        <a href={"https://api.whatsapp.com/send/?phone="+DatosContacto.whatsapp} rel="noreferrer" target="_blank">
                                            <WhatsAppIcon className="mx-3 bg-success text-white rounded p-1 shadow-sm" fontSize="large"></WhatsAppIcon>
                                        </a>
                                        <a href={DatosContacto.facebook} target="_blank" rel="noreferrer">
                                            <FacebookIcon className="mx-3 text-primary" fontSize="large"></FacebookIcon>
                                        </a>
                                        <a href={DatosContacto.instagram} target="_blank" rel="noreferrer">
                                            <InstagramIcon style={{color:"#ED4956"}} className="mx-3" fontSize="large"></InstagramIcon>
                                        </a>
                                    </div>
                                </div>
                                <Titulo>Encuentranos</Titulo>
                                <div className="mt-2 rounded shadow-sm">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.2884360871844!2d-71.56630282632915!3d-33.12563272528323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662754e6f2c5b01%3A0xeeb3d3f853423f77!2sAv%20Fundadores%20421%2C%20Placilla%2C%20Valpara%C3%ADso!5e0!3m2!1ses!2scl!4v1618105080486!5m2!1ses!2scl" height="250" allowFullScreen="" title="MapaVitral" style={{width:"100%"}} loading="lazy"></iframe>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PrintCargando(){
    return(
        <div className="d-flex flex-row align-items-center mt-2">
            <Typography className="mr-2">Cargando</Typography>
            <CircularProgress style={{ color: EstilosGlobales.colorPrimario }}></CircularProgress>
        </div>
    );
}
