import Header from '../components/Header'
import Footer from '../components/Footer'
import ContenidoTelemedicina from '../components/capacitaciones/ContenidoCapacitaciones'
import { Helmet } from 'react-helmet-async'

export default function Inicio(){
    return(
        <div>
            <Helmet>
                <title>
                    Capacitaciones - Vitral Salud
                </title>
            </Helmet>
            <Header></Header>
            <ContenidoTelemedicina></ContenidoTelemedicina>
            <Footer></Footer>
        </div>
    );
}

