
const EstilosGlobales = {
    colorPrimario:"#ba114b",
    colorPrimarioB:"#b57e91",
    colorSecundario:"#9d1252",
    colorTercero:"#00766f",
    colorCuarto:"#00a89f",
    colorQuinto:"#faaf00"
}

export default EstilosGlobales;