export default function Carousel(props) {

    
    
    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
                {
                    props.banners.map((banner,index)=>{
                        var activo = "no_active";
                        if(index<=0){
                            activo="active";
                        }
                        return(
                        <li key={index} data-target="#carouselExampleIndicators" data-slide-to={index} className={activo}></li>                        
                        );
                    })
                }
            </ol>
            <div className="carousel-inner">
                {
                    props.banners.map((banner,index)=>{
                        var activo = "no_active";
                        
                        if(index<=0){
                            activo="active";
                        }

                        
                        return(                        
                        <div key={index} className={"carousel-item "+activo}>
                            {
                                banner.link.length>0?
                                <a href={banner.link}>
                                    <img src={process.env.PUBLIC_URL+"/assets/imagenes/banners/"+banner.imagen} className="d-block w-100" alt="..." />
                                </a>
                                :
                                <img src={process.env.PUBLIC_URL+"/assets/imagenes/banners/"+banner.imagen} className="d-block w-100" alt="..." />
                                
                            }
                        </div>                      
                        );
                    })
                }                                
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
}