import { Card, Typography } from "@material-ui/core";
import Ruta from './Ruta'
import BannerTitulo from './BannerTitulo'
import Estilos from '../context/EstilosGlobales'
import { Link } from "react-router-dom";
export default function Contenido404(){
    return(
        <div>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Error 404"}                    
                ]}></Ruta>
                
                <BannerTitulo>Error 404</BannerTitulo> 
                <div className="bg-light">
                    <div className="container py-4">
                        <div className="row">
                            <div className="col">                            
                                <Card className="py-4 px-1 px-md-4" style={{minHeight:"300px"}}>
                                    <Typography style={{color:Estilos.colorPrimario}} className="font-weight-bold" variant="h4">Página no encontrada 404</Typography>
                                    <Typography variant="body1" className="mt-4">
                                        No hemos encontrado la página que andabas buscando. Puedes seguir navegando en <Link to="/">Vitral Salud</Link>
                                    </Typography>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}