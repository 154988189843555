import Header from '../components/Header'
import Footer from '../components/Footer'
import ContenidoAreaIntervencion from '../components/areaintervencion/ContenidoAreaIntervencion'

export default function AreaIntervencion(){
    return(
        <div>
            <Header></Header>
            <ContenidoAreaIntervencion></ContenidoAreaIntervencion>
            <Footer></Footer>
        </div>
    );
}