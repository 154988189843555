import Ruta from '../Ruta'
import BannerTitulo from '../BannerTitulo'
import { Card, Typography,CardActionArea } from '@material-ui/core'
import Titulo from '../Titulo'
import axios from 'axios';
import {useState,useEffect} from 'react'
import EstilosGlobales from '../../context/EstilosGlobales';
import { Skeleton } from '@material-ui/lab';
import { animated, useSpring } from 'react-spring';
import { Link } from 'react-router-dom';


export default function ContenidoNuestroEquipo(){

    const [Estado,setEstado] = useState("inicio");
    const [Equipo,setEquipo] = useState([]);
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarEquipo();
        }
    })
    
    const cargarEquipo=()=>{
    
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/nuestroequipo_cargar.php"
        }).then((resp)=>{
            var res = resp.data;            
            setEstado("cargado");
            setEquipo(res);
            
        })
    }

    return(
        <div>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Nuestro Equipo"},
                ]}></Ruta>
                
                <BannerTitulo>Nuestro Equipo</BannerTitulo>                
            </div>
            <div className="bg-light py-4">
                <div className="container">
                    <div className="row">
                        <div className="col px-1 px-md-4">
                            <Typography className="text-justify py-2 px-2">
                            Somos un equipo de profesionales con vocación de servicio en el área de la salud y educación. Hemos visto como el trabajo coordinado y generoso entre profesionales de distintas áreas consigue un mejor diagnóstico, crea un mejor plan de tratamiento e inserta con mayor éxito a un individuo en su medio ambiente de forma natural y como parte de su vida. 
                            Como equipo nos conocemos, tenemos reuniones continuamente, evaluamos casos en conjunto y hacemos del lugar de trabajo un ambiente saludable.
                            Continuamente estamos actualizando nuestros conocimientos y adaptándolos a la realidad de cada individuo y su familia.

                            </Typography>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Titulo className="my-4">Nuestros Profesionales</Titulo>
                    {
                        Estado==="cargado"?
                        <div className="row my-2">
                            {
                                Equipo.map((profesional,index)=>{
                                    return(
                                        <Profesional key={index} profesional={profesional} className="col-md-4 my-2"></Profesional>
                                    );
                                })
                            }    
                        </div>
                        :
                        <div className="row my-2">
                            <div className="col-md-4 my-2">
                                <Skeleton className="rounded" variant="rect" height={(212+80)}></Skeleton>                                
                            </div>
                            <div className="col-md-4 my-2">
                                <Skeleton className="rounded" variant="rect" height={(212+80)}></Skeleton>                                
                            </div>
                            <div className="col-md-4 my-2">
                                <Skeleton className="rounded" variant="rect" height={(212+80)}></Skeleton>                                
                            </div>
                            <div className="col-md-4 my-2">
                                <Skeleton className="rounded" variant="rect" height={(212+80)}></Skeleton>                                
                            </div>
                            <div className="col-md-4 my-2">
                                <Skeleton className="rounded" variant="rect" height={(212+80)}></Skeleton>                                
                            </div>
                            <div className="col-md-4 my-2">
                                <Skeleton className="rounded" variant="rect" height={(212+80)}></Skeleton>                                
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

function Profesional(props){

    const animprops = useSpring({
        opacity:1,
        transform:"translate(0px,0px)",
        from:{
            opacity:0,
            transform:"translate(0px,-20px)",
        }
    })

    let {className,profesional} = props;
    return(
        <animated.div style={animprops} className={className}>
            <Link className="text-decoration-none" to={"/nuestroequipo/profesional/"+profesional.nombre}>
                <Card>
                    <CardActionArea>
                        <div className="d-flex flex-row justify-content-center py-3">
                            <div className="rounded-circle shadow-sm p-1" style={{maxWidth:"180px",backgroundColor:EstilosGlobales.colorPrimario}}>
                                <img src={process.env.PUBLIC_URL+"/assets/imagenes/equipo/"+profesional.img} alt="..." className="img-fluid rounded-circle p-1 bg-white" />
                            </div>
                        </div>
                    </CardActionArea>
                    <div className="pb-4">
                        <Typography className="text-center font-weight-bold" variant="h6">
                            {profesional.nombre}
                        </Typography>
                        <Typography className="text-center" style={{color:EstilosGlobales.colorPrimario}}>
                            {profesional.cargo}
                        </Typography>
                    </div>
                </Card>
            </Link>
        </animated.div>
    );
}