import { useState, useEffect } from 'react'
import { List, ListItemText, Typography } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import RoomIcon from '@material-ui/icons/Room';
import axios from 'axios';
import Estilos from '../context/EstilosGlobales'


import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css'; // Add css for snow theme

function MiembroLista(props) {
    return (
        <ListItemText>
            <Link className="text-decoration-none text-white" to={props.ruta}>
                <ArrowRightIcon style={{ color: Estilos.colorPrimario }} fontSize="small"></ArrowRightIcon>
                {props.children}
            </Link>
        </ListItemText>
    );
}

export default function Footer() {

    const ClaseColFooter = "col-md-6 col-lg-3 my-2";

    const SeccionesVitral = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Quienes somos", ruta: "/qsomos" },        
        { nombre: "Historia", ruta: "/historia" },        
        { nombre: "Contacto", ruta: "/contacto" }
    ];

    const [Estado, setEstado] = useState("inicio");
    const [InfoGlobal, setInfoGlobal] = useState();

    useEffect(() => {
        if (Estado === "inicio") {
            cargarInfoGlobal();
        }
    })

    const cargarInfoGlobal = () => {

        setEstado("cargando");
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/global/config.json"
        }).then((resp) => {
            var res = resp.data;
            setInfoGlobal(res);
            setEstado("cargado");
        })
    }

    return (
        <div style={{backgroundColor:"#1a1919"}}>
            <div className="container pt-5">
                <div className="row">
                    <div className={ClaseColFooter}>
                        <div style={{ maxWidth: "300px" }}>
                            <img src={process.env.PUBLIC_URL+"/assets/imagenes/logo_contrast.png"} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className={ClaseColFooter}>
                        <Typography variant="body1" className="text-white font-weight-bold">SECCIONES VITRAL</Typography>
                        <List className="text-white">
                            {
                                SeccionesVitral.map((Seccion, index) => {
                                    return (
                                        <MiembroLista key={index} ruta={Seccion.ruta}>{Seccion.nombre}</MiembroLista>
                                    );
                                })
                            }
                        </List>
                    </div>
                    <div className={ClaseColFooter}>
                        <Typography variant="body1" className="text-white font-weight-bold">CONTACTO</Typography>
                        {
                            InfoGlobal ?
                                <List className="text-white">
                                    <ListItemText>
                                        <MailOutlineIcon fontSize="small"></MailOutlineIcon>
                                        <span className="ml-2">{InfoGlobal.Empresa.email}</span>
                                    </ListItemText>
                                    <ListItemText>
                                        <PhoneEnabledIcon fontSize="small"></PhoneEnabledIcon>
                                        <span className="ml-2">{InfoGlobal.Empresa.telefono}</span>
                                    </ListItemText>
                                    <ListItemText>
                                        <RoomIcon fontSize="small"></RoomIcon>
                                        <span className="ml-2">{InfoGlobal.Empresa.direccion}</span>
                                    </ListItemText>
                                </List> : ""
                        }
                    </div>
                    <div className={ClaseColFooter}>
                        <Typography variant="body1" className="text-white font-weight-bold">HORARIOS DE ATENCIÓN</Typography>
                        <HorariosAtencion></HorariosAtencion>
                    </div>
                </div>
                <div className="row justify-content-center py-3">
                    {InfoGlobal?
                        <div className="text-white">
                            Desarrollado por <a className="text-white text-decoration-none" href={InfoGlobal.Empresa_desarrollo.pagina}>{InfoGlobal.Empresa_desarrollo.nombre}</a> &copy; {(new Date().getFullYear())}
                        </div>:""
                    }
                </div>
            </div>
        </div>
    );
}

const HorariosAtencion = () => {
    const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    };
    

    const { quill, quillRef } = useQuill({ theme, modules});       

    useEffect(() => {
        var Estado = "inicio";

        const cargarHorario=()=>{
        
            axios({
                url:process.env.PUBLIC_URL+"/assets/api/horarios_cargar.php"
            }).then((resp)=>{
                var res = resp.data;            
                if(res){
                    quill.setContents(res);
                    Estado="cargado";
                }
            })
        }

        if(Estado==="inicio"){
            if(quill){
                cargarHorario();
                quill.disable();
            }
        }   
        
        
        
    }, [quill]);

    

    return (
        <div className="text-white border-0">
            <div className="border-0 p-0" ref={quillRef} />
        </div>
    );
};