import Ruta from '../Ruta'
import BannerTitulo from '../BannerTitulo'
import Titulo from '../Titulo'
import { Card, CardActionArea, Typography } from '@material-ui/core'
import { useParams } from 'react-router';
import {useState,useEffect} from 'react'
import axios from 'axios';
import {useQuill} from 'react-quilljs'
import { Link } from 'react-router-dom';
import EstilosGlobales from '../../context/EstilosGlobales'
import {animated,useSpring} from 'react-spring'
import { Helmet } from 'react-helmet-async';

export default function ContenidoAreaIntervencion(){

    let {getareo} = useParams();

    const [Estado,setEstado] = useState("inicio");
    const [Getareo,setGetareo] = useState({
        nombre:""
    });
    
    
    useEffect(()=>{
        if(Estado==="inicio" || getareo!==Getareo.nombre){
            cargarGetareo();
        }
    })
    
    const cargarGetareo=()=>{
    
        setEstado("cargando");

        var datos = new FormData();
        datos.append("getareo",getareo);

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/getareo_cargar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            /* console.log(res); */
            if(res[0]==="realizado"){
                setGetareo(res.datos_getareo);                
                setEstado("cargado");
            }
        })
    } 
    
    const animprops = useSpring({
        opacity:1,
        from:{
            opacity:0
        }
    })

    return(
        <div>
            <Helmet>
                <title>{Getareo.nombre+" - Área Intervención | Vitral"}</title>
            </Helmet>
            <div>
                <Ruta ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Area Intervención"},
                    {nombre:Getareo.nombre},
                ]}></Ruta>
                
                <BannerTitulo>Área de Intervención</BannerTitulo>                
            </div>
            
            {
                Estado==="cargado"?
                <div className="bg-light py-4">
                    <div className="container">
                        <animated.div styles={animprops} className="position-relative">
                            <div className="position-relative border shadow-sm overflow-hidden rounded-top" style={{height:"200px"}}>
                                <img src={process.env.PUBLIC_URL+"/assets/imagenes/grupos_etareos/"+Getareo.imagen} alt="gt" className="w-100" style={{transform:"translate(0px,-150px)",filter:"blur(18px)"}}/>
                                <div className="position-absolute w-100 h-100" style={{backgroundColor:"#1a1919",zIndex:"50",top:"0px",left:"0px",opacity:"0.3"}}></div>
                                
                            </div>
                            <div className="position-absolute d-flex flex-row align-items-center" style={{zIndex:"60",bottom:"10px",left:"40%",transform:"translate(-50%,0px)"}}>
                                <div>
                                    <div className="" style={{maxWidth:"300px"}}>
                                        <img src={process.env.PUBLIC_URL+"/assets/imagenes/grupos_etareos/"+Getareo.imagen} className="img-fluid" alt=""/>
                                    </div>                                 
                                </div>
                                <Typography className="text-white ml-5 font-weight-bold" variant="h4"> 
                                    {Getareo.nombre}
                                </Typography>
                            </div>
                        </animated.div>
                    </div>
                    <div className="container" style={{marginTop:"-10px"}}>
                        <div className="rounded-bottom shadow-sm border py-4">
                            <ContenidoGetareo contenido={Getareo.contenido}></ContenidoGetareo>
                        </div>
                    </div>
                    <Patologias></Patologias>
                </div>
                :
                ""
            }         
        </div>
    );
    
}

function ContenidoGetareo(props){
    let {contenido} = props

    const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    };
    

    const { quill, quillRef } = useQuill({ theme, modules});       


    useEffect(() => {
        var Estado = "inicio";

        const cargarContenido=()=>{
            Estado="cargado";
            quill.setContents(contenido);                         
        }

        if(Estado==="inicio"){
            if(quill){
                cargarContenido();
                quill.disable();
            }
        }                   
        
    }, [quill,contenido]);


    return(
        <div className="text-dark border-0">
            <div className="border-0 p-0" ref={quillRef} />
            {/* <Typography className="text-justify px-1 px-md-4">
                {Contenido}
            </Typography> */}
        </div>
    );
}

function Patologias(){

    let {getareo} = useParams();

    const [Estado,setEstado] = useState("inicio");
    const [Patologias,setPatologias] = useState([]);
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarPatologias();
        } 
    })
    
    const cargarPatologias=()=>{
    
        setEstado("cargando");
        var datos = new FormData();
        datos.append("getareo",getareo);
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/patologias_cargar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            if(res[0]==="realizado"){
                /* console.log(res['patologias']); */
                setEstado("cargado");
                setPatologias(res['patologias']);
            }
        })
    }

    return(
        <>
            {
                Estado==="cargado" && Patologias.length>0?
                <div className="container mt-4">
                    <Titulo>Patologías</Titulo>                 
                    <div className="row mt-3">
                        {
                            Patologias.map((patologia,index)=>{  
                                var random = Math.floor(Math.random() * 4) + 1; 
                                var color = EstilosGlobales.colorPrimario;
                                if(random===1) color=EstilosGlobales.colorPrimario;
                                else if(random===2) color=EstilosGlobales.colorTercero;
                                else if(random===3) color=EstilosGlobales.colorCuarto;
                                else if(random===4) color=EstilosGlobales.colorQuinto;
                                return(                                                         
                                    <Link to={"/areaintervencion/"+patologia.getareo+"/"+patologia.nombre} key={index} className="col-md-4 my-2 text-decoration-none">
                                        <Card>
                                            <CardActionArea>
                                                <div className="w-100 overflow-hidden" style={{height:"200px"}}>
                                                    <img src={process.env.PUBLIC_URL+"/assets/imagenes/patologias/"+patologia.imagen} alt="..." className="img-fluid w-100"/>
                                                </div>
                                            </CardActionArea>
                                            <div style={{backgroundColor:color}}>
                                                <Typography className="p-1 p-md-3 font-weight-bold text-center text-white">
                                                    {patologia.nombre}
                                                </Typography>
                                            </div>
                                        </Card>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                :""
            }
            
        </>
    );
}