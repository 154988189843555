import Header from '../components/Header'
import ContenidoNuestroEquipo from '../components/nuestroequipo/ContenidoNuestroEquipo'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async'

export default function Inicio(){
    return(
        <div>
            <Helmet>
                <title>Nuestro Equipo - Vitral Salud</title>
            </Helmet>
            <Header></Header>
            <ContenidoNuestroEquipo></ContenidoNuestroEquipo>
            <Footer></Footer>
        </div>
    );
}

