import {Typography} from '@material-ui/core'


export default function TituloContrast(props) {
    return (
        <div className={props.className}>
            <div className="row justify-content-start">
                <Typography className="font-weight-bold text-white" variant="h5">{props.titulo}</Typography>
            </div>
            <div className="row position-relative">
                <div className="border-bottom position-relative" style={{ width: "100%", height: "1px" }}>

                </div>
                <div className="position-absolute" style={{ width: "110px", height: "4px", left: "0%", backgroundColor: props.color, bottom: "-1.5px" }}></div>
            </div>
        </div>
    );
}