import Header from '../components/Header'
import Footer from '../components/Footer'
import ContenidoInicio from '../components/inicio/ContenidoInicio'
import { Helmet } from 'react-helmet-async'

export default function Inicio(){
    return(
        <div>
            <Helmet>
                <title>Vitral - Salud Integral</title>
            </Helmet>
            <Header></Header>
            <ContenidoInicio></ContenidoInicio>
            <Footer></Footer>
        </div>
    );
}

