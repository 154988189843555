import {Typography} from '@material-ui/core'
import Estilos from '../context/EstilosGlobales'

export default function Titulo(props) {
    return (
        <div className={props.className}>
            <div className="row mx-0">
                <Typography className="font-weight-bold" variant="h5">{props.titulo}{props.children}</Typography>
            </div>
            <div className="row mx-0 position-relative">
                <div className="border-bottom bg-dark position-relative" style={{ width: "100%", height: "1px" }}>
                </div>
                <div className="position-absolute" style={{ width: "40px", height: "4px", left: "0px", backgroundColor: Estilos.colorSecundario, bottom: "-1.5px" }}></div>
                <div className="position-absolute" style={{ width: "40px", height: "4px", left: "40px", backgroundColor: Estilos.colorTercero, bottom: "-1.5px" }}></div>
                <div className="position-absolute" style={{ width: "40px", height: "4px", left: "80px", backgroundColor: Estilos.colorQuinto, bottom: "-1.5px" }}></div>
                <div className="position-absolute" style={{ width: "40px", height: "4px", left: "120px", backgroundColor: Estilos.colorCuarto, bottom: "-1.5px" }}></div>
            </div>
        </div>
    );
}