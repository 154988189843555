import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');

import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { Button } from '@material-ui/core';
import { useEffect } from 'react';
import axios from 'axios';
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

export default function QuillGenerator() {
    /* const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    }; */
    

    /* const { quill, quillRef } = useQuill({ theme, modules});        */
    const { quill, quillRef } = useQuill();       

    useEffect(() => {
        var Estado = "inicio";

        const cargarHorario=()=>{
        
            axios({
                url:process.env.PUBLIC_URL+"/assets/datos/equipo/dra-andrea-arias.json"
            }).then((resp)=>{
                var res = resp.data;            
                if(res){
                    /* quill.setContents(res); */
                    Estado="cargado";
                }
            })
        }

        if(Estado==="inicio"){
            if(quill){
                cargarHorario();
                /* quill.disable(); */
            }
        }   
        
        
        
    }, [quill]);

    

    return (
        <div className="container">
            <div style={{ width: 800, height: 500 }}>
                <div ref={quillRef} />
            </div>
            <Button
            onClick={()=>{
                console.log(quill.getContents());
            }}
            variant="contained" color="primary" className="mt-5">Obtener</Button>
        </div>
    );
};
