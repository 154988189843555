import Header from '../components/Header'
import Footer from '../components/Footer'
import ContenidoQuienesSomos from '../components/quienessomos/ContenidoQuienesSomos'
import { Helmet } from 'react-helmet-async'

export default function Inicio(){
    return(
        <div>
            <Helmet>
                <title>Quiénes Somos - Vitral Salud</title>
            </Helmet>
            <Header></Header>
            <ContenidoQuienesSomos></ContenidoQuienesSomos>
            <Footer></Footer>
        </div>
    );
}

