import Header from '../components/Header'
import ContenidoProfesional from '../components/profesional/ContenidoProfesional'
import Footer from '../components/Footer'

export default function Inicio(){
    return(
        <div>
            <Header></Header>
            <ContenidoProfesional></ContenidoProfesional>
            <Footer></Footer>
        </div>
    );
}

